
import Pager from "@/views/layout/pager.vue";
import { useSelectItemWithChildList } from "@/network/lookUp.ts";
import { ref, reactive, toRef, getCurrentInstance, onMounted, defineComponent, toRefs, defineEmit, defineProps, inject } from "vue";
import { useAllowPerformanceScoreList, createPerformanceScore } from "@/network/projectFundApply"
import { ElMessageBox, ElMessage } from "element-plus";

export default defineComponent({
    components: {
        Pager
    },
    props:
    {
        setting: { type: Object },
    },
    emits: ["selected"],
    setup(props, { emit }) {
        const currentBatch: any = inject("currentBatch")
        const { isLoading, result, query, filter, pager } = useAllowPerformanceScoreList();


        const { visible } = toRefs(props.setting as any);

        const [project_ClassifyDic, project_SubClassifyDic] =
            useSelectItemWithChildList(
                toRef(filter, "project_Classify_Id"),
                toRef(filter, "project_SubClassify_Id"),
                "ProjectClassify",
                false
            );
        const onSelected = (id) => {

            ElMessage.success("绩效表格创建中...")
            isLoading.value = true;
            createPerformanceScore(id).then((res) => {
                isLoading.value = false;
                visible.value = false;
                emit("selected", res);
            })
        }

        const doQuery = (rFilter = null) => {
            query(rFilter);
        };



        onMounted(() => {
            doQuery();
        })

        return {
            isLoading,
            filter,
            currentBatch,
            doQuery,
            onSelected,
            project_ClassifyDic,
            project_SubClassifyDic,
            visible,
            pager,
            result
        }
    }
})

