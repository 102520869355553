
import { defineComponent, toRefs, ref } from "vue";

export default defineComponent({
  props: {
    setting: { type: Object },
    id: { type: Number },
  },
  setup(props, { emit }) {
    const { id } = toRefs(props);
    const { visible } = toRefs(props.setting as any);

    const selected = ref([]);
    const confirm = () => {
      //console.log(visible);

      visible.value = false;
      emit("confirm", { id: id.value, selected: selected.value });
    };
    return {
      visible,
      selected,
      confirm,
    };
  },
});
