
// import lnkToDetail from "@/views/heritageUnit/lnkToDetail"
import HeritageDetail from "@/views/heritageUnit/detail.vue";
import FundApplySubmitForm from "./fundApplySubmitForm.vue";
import PerformanceSubmitForm from "./performanceSubmitForm.vue";
import PerformanceViewForm from "./performanceViewForm.vue";
import PerformanceCreateSelectTable from "./performanceCreateSelectTable.vue";
import { useSelectItemWithChildList } from "@/network/lookUp.ts";
import { useCityRelTown, usefundCountriesDic } from "@/network/chinaArea";
// import Cart from "./cart.vue";
import { useAction } from "@/network/fundBatch.ts";
import { DocComponents } from "@/network/officialDoc.ts";
import Detail from "./detail.vue";
import Manage from "./manage.vue";
import FundReviewSelect from "./fundReviewSelect.vue";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import ShowRelBtn from "@/components/showRelBtn.vue";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";
import { copy } from "@/network/common";
import {
  useQueryProjectFundApply,
  useQueryCountProjectFundApply,
  useRunProjectFundApply,
  usePerformanceScore
} from "@/network/projectFundApply.ts";
import {
  provide,
  nextTick,
  toRef,
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
export default defineComponent({
  components: {
    PerformanceCreateSelectTable,
    // Cart,
    ...DocComponents,
    Detail,
    Manage,
    ShowRelBtn,
    FundReviewSelect,
    Base,
    Pager,
    PerformanceSubmitForm,
    PerformanceViewForm,
    FundApplySubmitForm,
    HeritageDetail
  },
  setup() {

    const topScrollWidth = ref(null);
    const refList = ref(null);
    const refTopScroll = ref(null);
    const isShowScroll = ref(false);
    const isShowTableHeader = ref(false);
    const dicYear = ref([]);
    const now = new Date();
    for (let i = -1; i < 9; i++) {
      dicYear.value.push((now.getFullYear() - i).toString());
    }


    const heritageSetting = reactive({ visible: false, id: null })


    const cartSetting = reactive({ visible: false });
    const isRefCartBtnAnFlag = ref(false);
    const refCartBtn = ref(null);
    const fundReviewSelectSetting = reactive({ visible: false });
    const fundApplySetting = reactive({
      visible: false,
      id: null,
      project_Code: null,
      project_Name: null,
      projectId: null,
      limit_Money: null,
      new_User_Id: null,
      status: null
    });

    const [, , , currentUse] = useAction()
    const currentBatch = ref(null);
    provide("currentBatch", currentBatch);
    currentUse().then(p => {
      currentBatch.value = p;
    })
    const currentSelectId = ref(null);
    const detailSetting = reactiveShowSetting({ review: false });
    const manageSetting = reactiveShowSetting({ review: false });

    const performanceCreateSelectTableSetting = reactive({
      visible: false
    })
    const performaceSubmitFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
    });
    const performaceViewFormSetting = reactive({
      id: currentSelectId,
      visible: false,
      project_Name: "",
      action: "view",
    });
    const docShowSetting = reactive({
      id: null,
      visible: false,
      approve: false,
    });
    const route = useRoute();
    const router = useRouter();
    const user: any = inject("user");
    const cartItems = ref([]);
    const [, , , , performanceScoreSubmit] = usePerformanceScore(ref(null));

    const exportExcel = () => {
      query({}, true);
    };
    const onManageChange = () => {
      query();
      //queryCount();
    };

    const onManageSubmit = ({ id, project_Name }) => {
      run(id).then((p) => {
        query();
        if (p != "Ok") {
          currentSelectId.value = p;
          performaceSubmitFormSetting.project_Name = project_Name;
          performaceSubmitFormSetting.visible = true;
        }
      });
    };



    const onCreatePerformanceForm = (item) => {
      showPerformanceSubmitForm(item)
      query();
    }

    const onPerformaceScoreSubmit = ({ id }) => {
      performanceScoreSubmit(id).then((p) => {
        query();
      });
    };

    const onPerformaceScoreReviewSubmit = ({ id }) => {
      query();
    };

    const addOrRevemoToCart = (item) => {
      const index = cartItems.value.indexOf(item);
      isRefCartBtnAnFlag.value = true;
      if (index == -1) {
        cartItems.value.push(item);
      } else {
        cartItems.value.splice(index, 1);
      }
    };

    const showReviewConfirm = (id) => {
      fundReviewSelectSetting.visible = true;
      currentSelectId.value = id;
    };

    const reviewConfirm = ({ id, selected }) => {
      run(id, { forceFlag: 6, args: selected });
    };

    const backConfirm = (id) => {
      ElMessageBox.prompt("请填写退回理由", "退回", {
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "退回",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "理由不得少于5个字！",
        inputPlaceholder: "请输入理由",
      }).then(({ value }) => {
        run(id, { forceFlag: 1, memo: value });
      });
    };

    const showPerformanceSubmitForm = ({
      performanceScoreId,
      project_Name,
    }) => {
      currentSelectId.value = performanceScoreId;
      performaceSubmitFormSetting.project_Name = project_Name;
      performaceSubmitFormSetting.visible = true;
    };

    const showFundApplyBtnTxt = (item) => {
      return item.status == 11 ? '查看' : ((item.applyStatus || 0) < 1 && item.new_User_Id == user.id ? '申请' : item.applyStatus == 1 && user.inRoles(roles.city)
        ? '会签' : item.applyStatus == 2 && user.inRoles(roles.prov) ? '安排' : '查看')
    }


    const showFundApplyForm = (item) => {
      fundApplySetting.id = item.fundId;
      fundApplySetting.visible = true;
      fundApplySetting.project_Code = item.project_Code;
      fundApplySetting.project_Name = item.project_Name;
      fundApplySetting.projectId = item.id;
      fundApplySetting.status = item.status;
      fundApplySetting.new_User_Id = item.new_User_Id;

      // console.log(item.assessment_Money, item.totalGive_Money);
      fundApplySetting.limit_Money =
        (item.assessment_Money || 0) - (item.totalGive_Money || 0);
    };
    const showPerformanceViewForm = ({ performanceScoreId, project_Name }) => {
      currentSelectId.value = performanceScoreId;
      performaceViewFormSetting.project_Name = project_Name;
      performaceViewFormSetting.visible = true;
      performaceViewFormSetting.action = "review";
    };

    const showApplyForm = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };

    const showPerformanceCreateSelectTable = () => {
      performanceCreateSelectTableSetting.visible = true;
      console.log(performanceCreateSelectTableSetting.visible)
    }

    const [count, queryCount] = useQueryCountProjectFundApply();

    const activeName = ref(route.query.tab);
    if (route.query.tab == null) {
      if (user.inRoles(roles.prov)) {
        activeName.value = "todo";
      } else {
        activeName.value = "draft";
      }
    }
    const [isProcessing, run] = useRunProjectFundApply(() => {
      query();
      //queryCount();
    });

    const [isLoading, result, query, filter, pager] = useQueryProjectFundApply();
    filter.fundApplyYear = dicYear.value[0];
    const resetFilter = copy(filter);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(filter, "project_Classify_Id"),
        toRef(filter, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );



    const statusDic = [
      { value: 1, label: "草稿" },
      { value: 3, label: "市级待审" },
      { value: 4, label: "省级待送审" },
      { value: 12, label: "省级已受理" },
      { value: 5, label: "评审中" },
      { value: 7, label: "评审办结" },
      { value: 9, label: "备选项目" },
    ];

    const doQuery = (rFilter) => {
      // if (activeName.value == "draft") cartSetting.visible = true;
      // else {
      //   cartSetting.visible = false;
      // }
      query(rFilter).then(p => {

        nextTick(() => {

          topScrollWidth.value = (refList.value.$refs.bodyWrapper.scrollWidth) + 'px';

          isShowScroll.value = refList.value.$el.className.includes('el-table--scrollable-x');

        })
      });
      //queryCount();
    };

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };
    const tabQuery = (v) => {
      cartItems.value.length = 0;
      Object.assign(filter, copy(resetFilter));
      if (v.paneName == "doing" && filter.childStatus == null) {
        filter.childStatus = user.inRoles(roles.prov) ? 12 : 4;
      } else {
        filter.childStatus = null;
      }
      //cartSetting.visible = true;
      doQuery({ status: v.paneName });

    };

    const affixChange = (s) => {
      isShowTableHeader.value = s;
    }

    onMounted(() => {

      refList.value.$refs.bodyWrapper.addEventListener('scroll', () => {
        // 表格顶部滚动条与底部同步
        let scrollLeft = refList.value.$refs.bodyWrapper.scrollLeft
        refTopScroll.value.scrollTo(scrollLeft, 0);
      })


      refTopScroll.value.addEventListener("scroll", () => {
        let scrollLeft = refTopScroll.value.scrollLeft;

        refList.value.$refs.bodyWrapper.scrollLeft = scrollLeft;
      })
      // if (route.query.action == "add") {
      //   showApplyForm(0);
      //   router.push({ path: route.path, query: {} });
      // }
      //console.log(111);
      if (user.inRoles(roles.city)) {
        resetFilter.city = filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   resetFilter.city = filter.city = user.city_ID;
        //   stop();
        // });
      }



      doQuery({
        status: activeName.value,
        childStatus:
          activeName.value == "doing"
            ? user.inRoles(roles.prov)
              ? 12
              : 4
            : null,
        reviewStatus: 0,
      });
    });
    return {
      //lnkToDetail,
      heritageSetting,
      currentBatch,
      performanceCreateSelectTableSetting,
      isShowTableHeader,
      affixChange,
      isShowScroll,
      refTopScroll,
      refList,
      topScrollWidth,
      dicYear,
      showDocContent,
      fundApplySetting,
      showFundApplyForm,
      showPerformanceCreateSelectTable,
      exportExcel,
      performanceScoreSubmit,
      showPerformanceViewForm,
      performaceViewFormSetting,
      showPerformanceSubmitForm,
      onPerformaceScoreSubmit,
      onPerformaceScoreReviewSubmit,
      performaceSubmitFormSetting,
      onCreatePerformanceForm,
      doQuery,
      statusDic,
      project_ClassifyDic,
      project_SubClassifyDic,
      dicCity,
      dicTown,
      cartSetting,
      isRefCartBtnAnFlag,
      refCartBtn,
      cartItems,
      addOrRevemoToCart,
      showReviewConfirm,
      reviewConfirm,
      backConfirm,
      tabQuery,
      showApplyForm,
      showFundApplyBtnTxt,
      docShowSetting,
      detailSetting,
      manageSetting,
      isLoading,
      isProcessing,
      run,
      roles,
      user,
      count,
      activeName,
      pager,
      data: result,
      filter,
      fundReviewSelectSetting,
      currentSelectId,
      onManageChange,
      onManageSubmit,
      fundCountries: usefundCountriesDic(),
    };
  },
});
