
import roles from "@/types/roles.ts";
import { defineComponent, toRefs, ref, watch, inject, onMounted } from "vue";
import { fundApplyRule } from "@/model/fundFormRules";
import { useFundApply, useQueryActionHistory } from "@/network/projectFundApply";
import LogHistory from "@/views/shared/logHistory.vue";
export default defineComponent({
  components: {
    LogHistory
  },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const user: any = inject("user");

    const editable = ref(false);
    const refForm = ref(null);
    const { id, visible } = toRefs(props.setting as any);
    const [isLoading, form, find, submit, back] = useFundApply(id);
    const [actionHistory, queryLog] = useQueryActionHistory(id, "SubmitFundApply");
    // fundApplyRule.applyAmount.push({
    //   required: true,
    //   message: `填报不正确，金额不得超过可申请资金!`,
    //   trigger: "blur",
    //   validator: (rule, value, callback) => {
    //     if (
    //       props.setting.limit_Money != null &&
    //       props.setting.limit_Money > 0 &&
    //       value > props.setting.limit_Money
    //     ) {
    //       return callback(Error(rule.message));
    //     }
    //     callback();
    //   },
    // });
    onMounted(() => {
      find().then((p) => {
        if (!form.id) {
          form.projectId = props.setting.projectId;
        }
        else {
          queryLog()
        }
        refForm.value.clearValidate();
        editable.value = props.setting.status != 11 && (((form.applyStatus || 0)  < 1 && props.setting.new_User_Id == user.id) ||
          (form.applyStatus == 1 && user.inRoles(roles.city)) || (form.applyStatus == 2 && user.inRoles(roles.prov)))


      });
    })

    const doBack = () => {
      back(form.id).then(p => {
        visible.value = false;
        emit("change");
      })
    }

    const confirm = () => {
      refForm.value.validate((valid, err) => {
        if (valid) {
          submit().then(() => {
            visible.value = false;
            emit("change");
          });
        }
        else {
          console.log(err)
        }
      });
      //console.log(visible);

      //emit("confirm", { id: id.value, selected: selected.value });
    };
    return {
      actionHistory,
      doBack,
      user,
      roles,
      editable,
      refForm,
      isLoading,
      visible,
      form,
      confirm,
      rules: fundApplyRule,
    };
  },
});
