
import { isEmpty } from "@/utils/validate";
import showDocContentBtn from "@/views/officialDoc/showDocContentBtn.vue";
import {
  useOfficialDoc,
  useGetOfficialDocTypes,
  useGetOfficialDocYears,
  useGetOfficialDocNumbers,
} from "@/network/officialDoc.ts";
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";

export default defineComponent({
  components: {
    showDocContentBtn,
  },
  emits: ["update:modelValue", "change", "showDocContent"],
  props: {
    modelValue: {
      type: Number,
    },
    allowSelect: {
      type: Boolean,
      default: true,
    },
    category: {
      type: Number,
      default: null,
    },
  },

  setup(props, { emit }) {
    const [isLoading, doc, find] = useOfficialDoc();
    const [isLoadingDocTypes, docTypes] = useGetOfficialDocTypes();
    const [isLoadingDocYears, docYears, fillDocYear] = useGetOfficialDocYears();
    const [isLoadingDocNumber, docNumbers, fillDocNumber] =
      useGetOfficialDocNumbers();
    const showDocContent = (id) => {
      emit("showDocContent", id);
    };
    const clear = () => {
      doc.id = null;
      doc.doc_Year = null;
      doc.doc_Serial_No = null;
      doc.doc_Type = null;
    };

    watch(
      () => doc.doc_Type,
      (v, o) => {
        docYears.value = null;
        if (!isEmpty(doc.doc_Type)) {
          fillDocYear(doc.doc_Type, props.category);
        }

        //console.log(v, o, doc.doc_Type);
      }
    );
    watch(
      () => doc.doc_Year,
      () => {
        docNumbers.value = null;
        if (!isEmpty(doc.doc_Year) && !isEmpty(doc.doc_Type))
          fillDocNumber(doc.doc_Type, doc.doc_Year, props.category);
        else {
          doc.id = null;
        }
      }
    );
    watch(
      () => doc.id,
      (v) => {
        if (!isLoadingDocNumber.value) {
          if (doc.id) {
            const selected = docNumbers.value.find((p) => p.value == doc.id);
            doc.id = selected?.id;
            doc.doc_Serial_No = selected?.doc_Serial_No;
            doc.doc_Title = selected?.doc_Title;
            doc.doc_Compile_No = selected?.doc_Compile_No;
            doc.isMuliNo = selected?.isMuliNo;
            doc.muliNoKey = selected?.muliNoKey;
            emit("update:modelValue", selected?.id);
          } else {
            doc.doc_Serial_No = null;
            doc.id = null;
            doc.doc_Title = null;
            doc.doc_Compile_No = null;
            doc.isMuliNo = false;
            doc.muliNoKey = null;
            emit("update:modelValue", null);
          }
        }
      }
    );
    onMounted(() => {
      if (props.modelValue) {
        const stop = watch(
          [isLoadingDocTypes, isLoadingDocYears, isLoadingDocNumber],
          () => {
            if (
              !isLoadingDocTypes.value &&
              !isLoadingDocYears.value &&
              !isLoadingDocNumber.value &&
              props.modelValue
            ) {
              find(props.modelValue);
              stop();
            }
          }
        );
      }
    });
    return {
      doc,
      isLoading,
      isLoadingDocTypes,
      isLoadingDocYears,
      isLoadingDocNumber,
      docTypes,
      docYears,
      docNumbers,
      showDocContent,
      clear,
    };
  },
});
