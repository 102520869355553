
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import LogHistory from "./logHistory.vue";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import officialDocSelect from "@/views/officialDoc/officialDocSelect.vue";
import { useFundFormRules } from "@/model/fundFormRules";
import { DocComponents, useInitOfficialDocObj } from "@/network/officialDoc.ts";

import { useSetupProjectAction } from "@/network/setupProject"
import fileupload from "@/components/fileupload";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
  Ref,
  inject,
  onUnmounted,
} from "vue";
import { ElMessage } from "element-plus";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
} from "@/network/lookUp.ts";
import enumGet from "@/types/enums";

import { useGetProjectPlanList } from "@/network/projectPlan";

import {
  useProjectFundApply,
  useQueryActionHistory,
} from "@/network/projectFundApply.ts";

import AnnualBudgetTable from "./annual_budgetTable.vue";
import BudgetContentTable from "./budget_contentTable.vue";

export default defineComponent({
  components: {
    LogHistory,
    ...DocComponents,
    officialDocSelect,
    fileupload,
    AnnualBudgetTable,
    BudgetContentTable,
    SelectUnit,
  },
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
      }>,
      default: () => {
        return {
          id: 0,
          visible: false,
        };
      },
    },
  },
  setup(props, { emit }) {

    const recreateFlag = ref(false);
    const proposalDocSelect = ref(null);
    const setupDocSelect = ref(null);
    const user: any = inject("user");
    const refAttachForm = ref(null);
    const refMainForm = ref(null);
    const { id, visible } = toRefs(props.setting as any);
    const isActionAdd = computed(() => {
      return id.value == 0 || id.value == null;
    });
    const activeName = ref("first");
    const heritageLevelDic = useSelectItemList("HeritageLevel");
    const firstCollapseActiveName = ref(null);
    const [rules, attachRules, setFormRule] = useFundFormRules(refMainForm);
    const docShowSetting = reactiveShowSetting();
    const [actionHistory, queryHistory] = useQueryActionHistory(id);
    const currentBatch = inject("currentBatch")

    const [isfindSetupPlanFileLoading,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      findSetupPlanFile] = useSetupProjectAction();
    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });

    const title = computed(() => {
      return `专项资金项目: ${form.project_Code == null ? "" : "[" + form.project_Code + "]"
        } ${form.project_Name}`;
    });

    const showDocContent = (id) => {
      docShowSetting.visible = true;
      docShowSetting.id = id;
    };
    const reload = () => {
      queryHistory();
      find().then(() => {
        if (isActionAdd.value) {
          //form.new_User_Id = user.id;
          //form.newDate = new Date();
        } else {
          // if (form.projectPlan_Id) findSetupPlanFile(form.projectPlan_Id);
        }
        selectUnitSetting.heritage_Name = form.heritageUnit.heritage_Name;
        setFormRule();
      });
      activeName.value = "first";
      firstCollapseActiveName.value = ["1", "2", "3", "4"];
      //secondCollapseActiveName.value = ["1", "2", "3"];
    };

    // watch(visible, (v) => {
    //   if (v) {
    //     reload();
    //   } else {
    //     id.value = null;
    //   }
    // });

    const doFindSetupPlanFile = (code) => {
      findSetupPlanFile(code).then(p => {
        if (!p) {
          ElMessage.error("未找到对应编号计划书备案信息！")
        }
        else {
          form.projectPlan_Id = p.setup_Id;
          form.proposalDoc_Id = p.proposalDoc_Id;
          form.setupDoc_Id = p.setupDoc_Id;
          form.heritageUnit_Id = p.heritageUnit_Id;

          selectUnitSetting.heritage_Name = form.heritageUnit.heritage_Name = p.heritage_Name;
          form.heritageUnit.heritageLevel_Code = p.heritageLevel_Code;


          // form.attachList = null

        }
      })

    }
    onMounted(() => {
      reload();
      activeName.value = "first";
      firstCollapseActiveName.value = ["1", "2", "3", "4"];
    });

    onUnmounted(() => {
      id.value = null;
    })

    const {
      loading,
      result: form,
      find,
      cancel,
      save,
      processing,
    } = useProjectFundApply(id);

    const selectUnitChange = (item) => {
      form.heritageUnit = item;
    };
    // const [isLoadingProjectPlan, projectPlanList, projectPlanListQuery] =
    //   useGetProjectPlanList();

    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(form, "project_Classify_Id"),
        toRef(form, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const clickToNext = () => {
      refMainForm.value.validate((valid) => {
        if (valid) {
          save().then(() => {
            id.value = form.id;
            activeName.value = "second";
            emit("change");
            queryHistory();
          });
        }
      });
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refMainForm.value.validate((valid) => {
          if (valid) {
            refAttachForm.value.validate((attachValid) => {
              if (attachValid) {
                save().then(() => {
                  resolve("success");

                  recreateFlag.value = true;
                  setTimeout(() => {
                    recreateFlag.value = false;
                  }, 10);
                  if (changeEmit) emit("change");
                });
              }
            });
          } else {
            activeName.value = "first";
            reject("error");
          }
        });
      });
    };

    const submit = () => {
      doSave(false)
        .then((p) => {
          emit("submit", { id: id.value, project_Name: form.project_Name });
          visible.value = false;
        })
        .catch((p) => {
          console.log(p);
        });
    };
    // watch(
    //   () => form.projectPlan_Id,
    //   (v) => {
    //     const item = projectPlanList.value.find((p) => p.id == v);
    //     if (item != null) {
    //       form.project_Classify_Id = item.project_Classify_Id;
    //       form.project_SubClassify_Id = item.project_SubClassify_Id;

    //       form.proposalDoc_Id = item.proposal_Doc_ID;
    //       form.setupDoc_Id = item.setup_Doc_ID;
    //     } else {
    //       form.project_Classify_Id = null;
    //       form.project_SubClassify_Id = null;

    //       form.proposalDoc_Id = null;
    //       form.setupDoc_Id = null;

    //       form.heritageUnit_Id = null;
    //     }
    //   }
    // );

    watch(
      () => form.hasProjectPlan,
      () => {
        form.proposalDoc_Id = null;
        form.setupDoc_Id = null;

        if (form.projectPlan_Id != null) {
          form.projectPlan_Id = null;
        }
        //selectUnitSetting.disabled = form.hasProjectPlan;
      }
    );

    const close = () => {
      if (cancel.value != null && loading.value) {
        cancel.value.cancel("取消加载...");
      }

      // proposalDocSelect.value.clear();
      // setupDocSelect.value.clear();
    };

    const onRemoveAttach = (res, i) => {
      i.fileAttach = null;
      i.fileAttachId = null;
    };
    const onUploadAttach = ({ url, name }, i, fileGroup) => {
      //i.fileAttach = Object.assign(i) { file_Path: url, file_Name: name };
      if (i.fileAttach == null) {
        i.fileAttach = { id: 0 };
      }
      i.fileAttach.file_Path = url;
      i.fileAttach.file_Name = name;

      refAttachForm.value.validateField(fileGroup)

      //console.log(i);
    };
    //console.log(JSON.stringify(form));
    return {
      isfindSetupPlanFileLoading,
      doFindSetupPlanFile,
      recreateFlag,
      currentBatch,
      user,
      showDocContent,
      dicLabel,
      heritageLevelDic,
      selectUnitSetting,
      selectUnitChange,
      actionHistory,
      close,
      visible,
      clickToNext,
      isActionAdd,
      activeName,
      firstCollapseActiveName,
      loading,
      doSave,
      submit,
      docShowSetting,
      title,
      form,
      rules,
      attachRules,
      refMainForm,
      refAttachForm,
      project_ClassifyDic,
      project_SubClassifyDic,
      processing,
      onRemoveAttach,
      onUploadAttach,
      proposalDocSelect,
      setupDocSelect,
    };
  },
});
