
import PerformanceScoreViewForm from "./performanceScoreViewForm";
import { defineComponent, toRefs, ref, Ref, reactive, watch, onMounted } from "vue";
import { usePerformanceScore } from "@/network/projectFundApply.ts";
import { performanceSubmitRule } from "@/model/fundFormRules.ts";
export default defineComponent({
  components: {
    PerformanceScoreViewForm,
  },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    // const form = reactive({
    //   id: 0,
    //   projectId: 0,
    //   fundYearIndex: "",
    //   useItem: "",
    //   provDept: "",
    //   doDept: "",
    //   yearAmount: 0,
    //   fiscalAmount: 0,
    //   otherAmount: 0,
    //   aim: "",
    //   fileAttaches: [],
    //   performanceScoreItems: [
    //     { first: "产出指标", second: "数量指标", third: "", value: "" },
    //     { first: "产出指标", second: "质量指标", third: "", value: "" },
    //     { first: "产出指标", second: "时效指标", third: "", value: "" },
    //     { first: "产出指标", second: "成本指标", third: "", value: "" },
    //     { first: "效益指标", second: "经济效益指标", third: "", value: "" },
    //     { first: "效益指标", second: "社会效益指标", third: "", value: "" },
    //     { first: "效益指标", second: "生态效益指标", third: "", value: "" },
    //     { first: "效益指标", second: "可持续影响指标", third: "", value: "" },
    //     {
    //       first: "满意度指标",
    //       second: "服务对象满意度指标",
    //       third: "",
    //       value: "",
    //     },
    //   ],
    // });
    const viewAppraise = ref({});
    const viewFormSetting = reactive({ visible: false });
    const refForm = ref(null);
    const { visible, id } = toRefs(props.setting as any);

    const [isLoading, form, find, save, submit] = usePerformanceScore(id);
    const showScoreDetail = (item) => {
      viewAppraise.value = item;
      viewFormSetting.visible = true;
    };

    onMounted(()=>{
      find();
    })
    // watch(visible, (v) => {
    //   if (v) {
    //     find();
    //   }
    // });
    const addDetailRow = (row, index) => {
      form.performanceScoreItems.splice(index + 1, 0, {
        first: row.first,
        second: row.second,
        third: "",
        value: "",
      });
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save().then(() => {
              resolve("success");
              if (changeEmit) emit("change");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const confirm = () => {
      //console.log(visible);
      doSave(false).then(() => {
        visible.value = false;
        emit("confirm", { id: id.value });
      });
    };
    return {
      performanceSubmitRule,
      showScoreDetail,
      viewFormSetting,
      viewAppraise,
      doSave,
      refForm,
      isLoading,
      addDetailRow,
      visible,
      confirm,
      form,
    };
  },
});
